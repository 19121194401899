import { asset_cdn } from './helpers';

export const getAvatar = (seed: string | number) => `https://avatars.bitdegree.org/8.x/bottts/svg?seed=${seed}`;

export const getRandomAvatar = (possibleVariantsCount: number) => {
    const random = Math.floor(Math.random() * possibleVariantsCount);

    return getAvatar(random.toString());
};

export const generateAvatarsList = (count: number, possibleVariantsCount: number) => [...Array(count).keys()].map(() => getRandomAvatar(possibleVariantsCount));

export const getStaticAvatarsImage = (index: number) => {
    const primary = index % 10;
    const key = primary === 0 ? 10 : primary;

    return asset_cdn(`assets/missions/profile/${key}.svg`);
};

export const getRandomStaticAvatarsImage = () => {
    const random = Math.floor(Math.random() * 10);

    return getStaticAvatarsImage(random);
};
